import { GraphQLClient } from 'graphql-request'
import { getSdk } from './schema'
import { env } from '../env'

const { REACT_APP_GRAPHQL_API } = env
if (!REACT_APP_GRAPHQL_API) throw new Error('REACT_APP_GRAPHQL_API required')

const client = new GraphQLClient(REACT_APP_GRAPHQL_API)
const api = getSdk(client)

export default api