import React, { useState, useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import '@fontsource/inter/variable.css'
// eslint-disable-next-line import/no-webpack-loader-syntax
import sumsubStyles from '!!file-loader!./sumsub.css'
import SumsubWebSdk from '@sumsub/websdk-react'
import api from './graphql/api'

function App() {
  // get access token from url
  const url = new URL(window.location.href);
  const query = url.searchParams;
  const accessToken = query.get('auth')
  const jwt = query.get('jwt')

  const [token, setToken] = useState<string>(accessToken || '')
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    if (!accessToken && !jwt) {
      setError(true)
      return
    }
    if (jwt) {
      // parse jwt and get user id
      try {
        const jwtParts = jwt.split('.')
        const payload = JSON.parse(atob(jwtParts[1]))
        const userId = payload.sub as string

        if (!userId) throw new Error('no user id')

        api.generateSumsubToken({
          input: {
            publicId: userId,
          }
        }).then(result => {
          const token = result.generateSumsubTokenForContact?.contact?.token
          if (token) {
            setToken(token)
          } else {
            setError(true)
          }
        })
      } catch (e) {
        setError(true)
      }
    }
  }, [accessToken, jwt])

  return (
    <div className='app'>
      <div className='wrapper'>
        {token ?
          <SumsubWebSdk
            baseUrl='https://api.cyberity.ru'
            accessToken={token}
            expirationHandler={async () => {
              setError(true)
              return ''
            }}
            config={{
              lang: 'ru',
              uiConf: {
                customCss: url.origin + sumsubStyles
              }
            }}
          />
          :
          <div className='text-center'>
            <div>
              <img src={logo} height='50px' className='App-logo' alt='logo' />
            </div>
            {error &&
              <>
                <div className='title'>Ссылка устарела или недействительна</div>
                <div className='subtitle'>Пожалуйста, запросите новую ссылку для верификации</div>
              </>
            }
          </div>
        }
      </div>
    </div >
  );
}

export default App;
