import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** DateTime in ISO-8601 format */
  Iso8601DateTime: any;
  /** The `Iterable` scalar type represents an array or a Traversable with any kind of data. */
  Iterable: any;
  /** Monetary amount in pennies of Rouble */
  RoublePenniesAmmount: any;
  /** The `Upload` type represents a file to be uploaded in the same HTTP request as specified by [graphql-multipart-request-spec](https://github.com/jaydenseric/graphql-multipart-request-spec). */
  Upload: any;
};

export type Application = Node & {
  __typename?: 'Application';
  _id: Scalars['Int'];
  contacts?: Maybe<ContactConnection>;
  created: Scalars['Iso8601DateTime'];
  crmStatus: Scalars['String'];
  customerCompany?: Maybe<CustomerCompany>;
  id: Scalars['ID'];
  reconciliations?: Maybe<ReconciliationConnection>;
  source: Source;
  sourceData?: Maybe<Scalars['String']>;
  utmSource?: Maybe<Scalars['String']>;
};


export type ApplicationContactsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type ApplicationReconciliationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  application_id?: InputMaybe<Scalars['Int']>;
  application_id_list?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  before?: InputMaybe<Scalars['String']>;
  createdBy_id?: InputMaybe<Scalars['Int']>;
  createdBy_id_list?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  first?: InputMaybe<Scalars['Int']>;
  isActual?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<ReconciliationFilter_Order>>>;
};

/** Connection for Application. */
export type ApplicationConnection = {
  __typename?: 'ApplicationConnection';
  edges?: Maybe<Array<Maybe<ApplicationEdge>>>;
  pageInfo: ApplicationPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of Application. */
export type ApplicationEdge = {
  __typename?: 'ApplicationEdge';
  cursor: Scalars['String'];
  node?: Maybe<Application>;
};

/** Information about the current page. */
export type ApplicationPageInfo = {
  __typename?: 'ApplicationPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type Contact = Node & {
  __typename?: 'Contact';
  _id: Scalars['Int'];
  created: Scalars['Iso8601DateTime'];
  email: Scalars['String'];
  fio: Scalars['String'];
  id: Scalars['ID'];
  phone: Scalars['String'];
  publicId: Scalars['String'];
  sumSubApplicantData?: Maybe<Scalars['String']>;
};

/** Connection for Contact. */
export type ContactConnection = {
  __typename?: 'ContactConnection';
  edges?: Maybe<Array<Maybe<ContactEdge>>>;
  pageInfo: ContactPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of Contact. */
export type ContactEdge = {
  __typename?: 'ContactEdge';
  cursor: Scalars['String'];
  node?: Maybe<Contact>;
};

/** Information about the current page. */
export type ContactPageInfo = {
  __typename?: 'ContactPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type CustomerCompany = Node & {
  __typename?: 'CustomerCompany';
  _id: Scalars['Int'];
  created?: Maybe<Scalars['Iso8601DateTime']>;
  id: Scalars['ID'];
  inn: Scalars['String'];
  kpp?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  needsUpdate: Scalars['Boolean'];
  okpo?: Maybe<Scalars['String']>;
  opf?: Maybe<Scalars['String']>;
  registrationDate?: Maybe<Scalars['Iso8601DateTime']>;
  updated?: Maybe<Scalars['Iso8601DateTime']>;
};

/** Connection for CustomerCompany. */
export type CustomerCompanyConnection = {
  __typename?: 'CustomerCompanyConnection';
  edges?: Maybe<Array<Maybe<CustomerCompanyEdge>>>;
  pageInfo: CustomerCompanyPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of CustomerCompany. */
export type CustomerCompanyEdge = {
  __typename?: 'CustomerCompanyEdge';
  cursor: Scalars['String'];
  node?: Maybe<CustomerCompany>;
};

/** Information about the current page. */
export type CustomerCompanyPageInfo = {
  __typename?: 'CustomerCompanyPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type Document = Node & {
  __typename?: 'Document';
  _id: Scalars['Int'];
  application?: Maybe<Application>;
  contact?: Maybe<Contact>;
  created: Scalars['Iso8601DateTime'];
  customerCompany?: Maybe<CustomerCompany>;
  filePath: Scalars['String'];
  id: Scalars['ID'];
  mimeType: Scalars['String'];
  originalName: Scalars['String'];
  size: Scalars['Int'];
  type: DocumentType;
};

/** Connection for Document. */
export type DocumentConnection = {
  __typename?: 'DocumentConnection';
  edges?: Maybe<Array<Maybe<DocumentEdge>>>;
  pageInfo: DocumentPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of Document. */
export type DocumentEdge = {
  __typename?: 'DocumentEdge';
  cursor: Scalars['String'];
  node?: Maybe<Document>;
};

/** Information about the current page. */
export type DocumentPageInfo = {
  __typename?: 'DocumentPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type DocumentType = Node & {
  __typename?: 'DocumentType';
  _id: Scalars['Int'];
  created: Scalars['Iso8601DateTime'];
  entity: DocumentTypeEntity;
  id: Scalars['ID'];
  type: Scalars['String'];
};

/** Connection for DocumentType. */
export type DocumentTypeConnection = {
  __typename?: 'DocumentTypeConnection';
  edges?: Maybe<Array<Maybe<DocumentTypeEdge>>>;
  pageInfo: DocumentTypePageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of DocumentType. */
export type DocumentTypeEdge = {
  __typename?: 'DocumentTypeEdge';
  cursor: Scalars['String'];
  node?: Maybe<DocumentType>;
};

/** Entity associated with the Document Type */
export enum DocumentTypeEntity {
  Application = 'application',
  Contact = 'contact',
  CustomerCompany = 'customer_company'
}

/** Information about the current page. */
export type DocumentTypePageInfo = {
  __typename?: 'DocumentTypePageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Adds an application. */
  addApplication?: Maybe<AddApplicationPayload>;
  /** Adds a Contact. */
  addContact?: Maybe<AddContactPayload>;
  /** Adds document type. */
  addDocumentType?: Maybe<AddDocumentTypePayload>;
  /** Adds a reconciliation for an application. */
  addReconciliation?: Maybe<AddReconciliationPayload>;
  /** Generates SumSub access token for a Contact. */
  generateSumsubTokenForContact?: Maybe<GenerateSumsubTokenForContactPayload>;
  /** Logins a User. */
  loginUser?: Maybe<LoginUserPayload>;
  /** Makes reconciliation actual for its application. */
  makeActualReconciliation?: Maybe<MakeActualReconciliationPayload>;
  /** RefreshTokens a User. */
  refreshTokenUser?: Maybe<RefreshTokenUserPayload>;
  /** Deletes reconciliation. */
  removeApplication?: Maybe<RemoveApplicationPayload>;
  /** Deletes reconciliation. */
  removeReconciliation?: Maybe<RemoveReconciliationPayload>;
  /** Uploads document. */
  uploadDocument?: Maybe<UploadDocumentPayload>;
};


export type MutationAddApplicationArgs = {
  input: AddApplicationInput;
};


export type MutationAddContactArgs = {
  input: AddContactInput;
};


export type MutationAddDocumentTypeArgs = {
  input: AddDocumentTypeInput;
};


export type MutationAddReconciliationArgs = {
  input: AddReconciliationInput;
};


export type MutationGenerateSumsubTokenForContactArgs = {
  input: GenerateSumsubTokenForContactInput;
};


export type MutationLoginUserArgs = {
  input: LoginUserInput;
};


export type MutationMakeActualReconciliationArgs = {
  input: MakeActualReconciliationInput;
};


export type MutationRefreshTokenUserArgs = {
  input: RefreshTokenUserInput;
};


export type MutationRemoveApplicationArgs = {
  input: RemoveApplicationInput;
};


export type MutationRemoveReconciliationArgs = {
  input: RemoveReconciliationInput;
};


export type MutationUploadDocumentArgs = {
  input: UploadDocumentInput;
};

/** A node, according to the Relay specification. */
export type Node = {
  /** The id of this node. */
  id: Scalars['ID'];
};

export type Query = {
  __typename?: 'Query';
  application?: Maybe<Application>;
  applications?: Maybe<ApplicationConnection>;
  contact?: Maybe<Contact>;
  contacts?: Maybe<ContactConnection>;
  customerCompanies?: Maybe<CustomerCompanyConnection>;
  customerCompany?: Maybe<CustomerCompany>;
  document?: Maybe<Document>;
  documentType?: Maybe<DocumentType>;
  documentTypes?: Maybe<DocumentTypeConnection>;
  documents?: Maybe<DocumentConnection>;
  node?: Maybe<Node>;
  reconciliation?: Maybe<Reconciliation>;
  reconciliations?: Maybe<ReconciliationConnection>;
  source?: Maybe<Source>;
  sources?: Maybe<SourceConnection>;
  user?: Maybe<User>;
  users?: Maybe<UserConnection>;
};


export type QueryApplicationArgs = {
  id: Scalars['ID'];
};


export type QueryApplicationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryContactArgs = {
  id: Scalars['ID'];
};


export type QueryContactsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryCustomerCompaniesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryCustomerCompanyArgs = {
  id: Scalars['ID'];
};


export type QueryDocumentArgs = {
  id: Scalars['ID'];
};


export type QueryDocumentTypeArgs = {
  id: Scalars['ID'];
};


export type QueryDocumentTypesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryDocumentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryNodeArgs = {
  id: Scalars['ID'];
};


export type QueryReconciliationArgs = {
  id: Scalars['ID'];
};


export type QueryReconciliationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  application_id?: InputMaybe<Scalars['Int']>;
  application_id_list?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  before?: InputMaybe<Scalars['String']>;
  createdBy_id?: InputMaybe<Scalars['Int']>;
  createdBy_id_list?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  first?: InputMaybe<Scalars['Int']>;
  isActual?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<ReconciliationFilter_Order>>>;
};


export type QuerySourceArgs = {
  id: Scalars['ID'];
};


export type QuerySourcesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  name_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};


export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type Reconciliation = Node & {
  __typename?: 'Reconciliation';
  _id: Scalars['Int'];
  advanceRate: Scalars['Float'];
  amount: Scalars['RoublePenniesAmmount'];
  application: Application;
  comissionRate: Scalars['Float'];
  created: Scalars['Iso8601DateTime'];
  createdBy: User;
  durationMonths: Scalars['Int'];
  expirationDate: Scalars['Iso8601DateTime'];
  id: Scalars['ID'];
  insuranceRate: Scalars['Float'];
  interestRate: Scalars['Float'];
  isActual: Scalars['Boolean'];
  updated?: Maybe<Scalars['Iso8601DateTime']>;
  vatRate: Scalars['Float'];
};

/** Connection for Reconciliation. */
export type ReconciliationConnection = {
  __typename?: 'ReconciliationConnection';
  edges?: Maybe<Array<Maybe<ReconciliationEdge>>>;
  pageInfo: ReconciliationPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of Reconciliation. */
export type ReconciliationEdge = {
  __typename?: 'ReconciliationEdge';
  cursor: Scalars['String'];
  node?: Maybe<Reconciliation>;
};

export type ReconciliationFilter_Order = {
  created?: InputMaybe<Scalars['String']>;
};

/** Information about the current page. */
export type ReconciliationPageInfo = {
  __typename?: 'ReconciliationPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type Source = Node & {
  __typename?: 'Source';
  _id: Scalars['Int'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** Connection for Source. */
export type SourceConnection = {
  __typename?: 'SourceConnection';
  edges?: Maybe<Array<Maybe<SourceEdge>>>;
  pageInfo: SourcePageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of Source. */
export type SourceEdge = {
  __typename?: 'SourceEdge';
  cursor: Scalars['String'];
  node?: Maybe<Source>;
};

/** Information about the current page. */
export type SourcePageInfo = {
  __typename?: 'SourcePageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type User = Node & {
  __typename?: 'User';
  _id: Scalars['Int'];
  email: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  phone: Scalars['String'];
  roles: Scalars['Iterable'];
  surname: Scalars['String'];
};

/** Connection for User. */
export type UserConnection = {
  __typename?: 'UserConnection';
  edges?: Maybe<Array<Maybe<UserEdge>>>;
  pageInfo: UserPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of User. */
export type UserEdge = {
  __typename?: 'UserEdge';
  cursor: Scalars['String'];
  node?: Maybe<User>;
};

/** Information about the current page. */
export type UserPageInfo = {
  __typename?: 'UserPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type AddApplicationInput = {
  /** Category ID */
  category?: InputMaybe<Scalars['ID']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Client email */
  email: Scalars['String'];
  /** Client full name */
  fio: Scalars['String'];
  /** Client company INN */
  inn: Scalars['String'];
  /** Client phone number */
  phone: Scalars['String'];
  /** Source ID */
  source: Scalars['ID'];
  /** Additional source data as json encoded string */
  sourceDataJson?: InputMaybe<Scalars['String']>;
  /** Utm source */
  utmSource?: InputMaybe<Scalars['String']>;
};

export type AddApplicationPayload = {
  __typename?: 'addApplicationPayload';
  application?: Maybe<Application>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AddContactInput = {
  /** Application ID */
  application: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Contact email */
  email: Scalars['String'];
  /** Contact full name */
  fio: Scalars['String'];
  /** Contact phone number */
  phone: Scalars['String'];
};

export type AddContactPayload = {
  __typename?: 'addContactPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  contact?: Maybe<Contact>;
};

export type AddDocumentTypeInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Entity */
  entity?: InputMaybe<DocumentTypeEntity>;
  /** Type */
  type: Scalars['String'];
};

export type AddDocumentTypePayload = {
  __typename?: 'addDocumentTypePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  documentType?: Maybe<DocumentType>;
};

export type AddReconciliationInput = {
  /** Advance rate percentage (regex /^\d{1,6}\.?\d{0,4}$/) */
  advanceRate: Scalars['Float'];
  /** Amount in pennies */
  amount: Scalars['RoublePenniesAmmount'];
  /** Application ID */
  application: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Comission rate percentage (regex /^\d{1,6}\.?\d{0,4}$/) */
  comissionRate: Scalars['Float'];
  /** Duration (months, minimum 6) */
  durationMonths: Scalars['Int'];
  /** Expiration date, ISO-8601 */
  expirationDate: Scalars['Iso8601DateTime'];
  /** Insurance rate percentage (regex /^\d{1,6}\.?\d{0,4}$/) */
  insuranceRate: Scalars['Float'];
  /** Interest rate percentage (regex /^\d{1,6}\.?\d{0,4}$/) */
  interestRate: Scalars['Float'];
  /** Vat rate percentage (regex /^\d{1,6}\.?\d{0,4}$/) */
  vatRate: Scalars['Float'];
};

export type AddReconciliationPayload = {
  __typename?: 'addReconciliationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  reconciliation?: Maybe<Reconciliation>;
};

export type GenerateSumsubTokenForContactInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Public ID of the Contact */
  publicId: Scalars['String'];
};

export type GenerateSumsubTokenForContactPayload = {
  __typename?: 'generateSumsubTokenForContactPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  contact?: Maybe<GenerateSumsubTokenForContactPayloadData>;
};

export type GenerateSumsubTokenForContactPayloadData = Node & {
  __typename?: 'generateSumsubTokenForContactPayloadData';
  id: Scalars['ID'];
  publicId: Scalars['String'];
  token: Scalars['String'];
};

export type LoginUserInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** User email */
  login: Scalars['String'];
  /** User password */
  password: Scalars['String'];
};

export type LoginUserPayload = {
  __typename?: 'loginUserPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  user?: Maybe<LoginUserPayloadData>;
};

export type LoginUserPayloadData = Node & {
  __typename?: 'loginUserPayloadData';
  id: Scalars['ID'];
  jwt: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type MakeActualReconciliationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Reconciliation ID */
  reconciliation: Scalars['ID'];
};

export type MakeActualReconciliationPayload = {
  __typename?: 'makeActualReconciliationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  reconciliation?: Maybe<Reconciliation>;
};

export type RefreshTokenUserInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Refresh token */
  refresh_token: Scalars['String'];
};

export type RefreshTokenUserPayload = {
  __typename?: 'refreshTokenUserPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  user?: Maybe<RefreshTokenUserPayloadData>;
};

export type RefreshTokenUserPayloadData = Node & {
  __typename?: 'refreshTokenUserPayloadData';
  id: Scalars['ID'];
  jwt: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type RemoveApplicationInput = {
  /** Application ID */
  application: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
};

export type RemoveApplicationPayload = {
  __typename?: 'removeApplicationPayload';
  application?: Maybe<Application>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveReconciliationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Reconciliation ID */
  reconciliation: Scalars['ID'];
};

export type RemoveReconciliationPayload = {
  __typename?: 'removeReconciliationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  reconciliation?: Maybe<Reconciliation>;
};

export type UploadDocumentInput = {
  /** Application ID */
  application?: InputMaybe<Scalars['ID']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Contact ID */
  contact?: InputMaybe<Scalars['ID']>;
  /** Customer Company ID */
  customerCompany?: InputMaybe<Scalars['ID']>;
  /** The file to upload */
  file: Scalars['Upload'];
  /** Document Type ID */
  type: Scalars['ID'];
};

export type UploadDocumentPayload = {
  __typename?: 'uploadDocumentPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  document?: Maybe<Document>;
};

export type GenerateSumsubTokenMutationVariables = Exact<{
  input: GenerateSumsubTokenForContactInput;
}>;


export type GenerateSumsubTokenMutation = { __typename?: 'Mutation', generateSumsubTokenForContact?: { __typename?: 'generateSumsubTokenForContactPayload', contact?: { __typename?: 'generateSumsubTokenForContactPayloadData', id: string, token: string } | null } | null };


export const GenerateSumsubTokenDocument = gql`
    mutation generateSumsubToken($input: generateSumsubTokenForContactInput!) {
  generateSumsubTokenForContact(input: $input) {
    contact {
      id
      token
    }
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    generateSumsubToken(variables: GenerateSumsubTokenMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GenerateSumsubTokenMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<GenerateSumsubTokenMutation>(GenerateSumsubTokenDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'generateSumsubToken');
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;